import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaList, FaStore, FaHatWizard, FaPalette} from 'react-icons/fa'
import { Flex, chakra, Box } from "@chakra-ui/react";
import "./before.css";

export default function Timeline() {

    const mystyle = {
      backgroundImage: 'url("croakz/rilla-web.jpg")',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '25% 30%',
      //filter: 'brightness(80%)'
    } as React.CSSProperties;

    // const darken = {
    //   background: 'rgba(0, 0, 0, .50)'
    // }

    return (
        <Flex className="beforeborder" style={mystyle}>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#58ac9a', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid #fff' }}
                    iconStyle={{ background: '#58ac9a', color: '#fff' }}
                    icon={<FaList />}
                >
                    <chakra.span className="vertical-timeline-element-title" fontSize="2xl" fontWeight="medium">Wen Mint?</chakra.span>
                    <Box fontSize="md" fontWeight="medium" letterSpacing="wider">
                        Quality first. CROAKZ will have a crafted feel of excellence. Living up to this standard
                        extends beyond the CROAKZ mint. Opportunities are endless as we near the metaverse. So letz get ready to leap! Mint details 
                        will be announced as soon as our team feels the CROAKZ collection has 
                        met and surpassed our standards and expectations.
                    </Box>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#6a7593', color: '#fff' }}
                    iconStyle={{ background: '#6a7593', color: '#fff' }}
                    icon={<FaStore />}
                >
                    <chakra.span className="vertical-timeline-element-title" fontSize="2xl" fontWeight="medium">Meatspace</chakra.span>
                    <Box fontSize="md" fontWeight="medium" letterSpacing="wider">
                        CROAKZ are positioned perfectly for adoption within Meatspace (the physical, tangible world).
                        We will seek out reputable studios that specialize in quality vinyl models and toys, with the dream
                        that a tangible CROAKZ may soon chill next to you on your desk. We are in the 
                        early concept stages. Part of this quest for tangible CROAKZ will include 3d-printable CROAKZ, gummies, candies,
                        hats, shirts, patches, and more!
                    </Box>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#6a7593', color: '#fff' }}
                    iconStyle={{ background: '#6a7593', color: '#fff' }}
                    icon={<FaHatWizard />}
                >
                    <chakra.span className="vertical-timeline-element-title" fontSize="2xl" fontWeight="medium">Metaverse</chakra.span>
                    <Box fontSize="md" fontWeight="medium" letterSpacing="wider">
                        3D CROAKZ. 3D metaverse. It just makes sense! We want CROAKZ to be a staple in any future VR/AR ecosystem.
                        We are ready for opportunities that bring life to CROAKZ in the metaverse. The current models will need to be
                        massaged further and rigged for interactive 3D experiences, but the team is willing to give it a go.
                        Current ideas range from a simple 3D interactive viewing gallery of the collection to a full-fledged 3D game.
                        Patience though. This will take time!
                    </Box>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#6a7593', color: '#fff' }}
                    iconStyle={{ background: '#6a7593', color: '#fff' }}
                    icon={<FaPalette />}
                >
                    <chakra.span className="vertical-timeline-element-title" fontSize="2xl" fontWeight="medium">Art and Team</chakra.span>
                    <Box fontSize="md" fontWeight="medium" letterSpacing="wider">
                        CROAKZ is a project born from love. The team envisions various avenues to explore with these adorable creatures.
                        The CROAKZ bog is a rich landscape of creative potential. We want to collaborate with different NFT artists.
                        We want to create animations, or even short films. The sky is the limit, and with a solid community behind 
                        us providing input and help, we know CROAKZ can be a premium brand in the metaverse. 
                        We are counting on you joining the bog! Letz croak, vibe, and together leap to the moon!
                    </Box>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </Flex>
    );
}