import { 
  ChakraProvider,
  useDisclosure,
  useColorModeValue,
  Box,
  Text,
  Image,
  Flex,
  Wrap,
  WrapItem,
  Spacer,
  ButtonGroupProps,
  Stack,
  TextProps,
  ButtonGroup,
  IconButton 
} from "@chakra-ui/react";

import Fonts from "./components/Fonts"
import theme from "./theme";

import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";

import Mint from "./components/Mint";

import Mf3rCroakMint from "./components/Mf3rCroakMint";
import Mf3rMferMint from "./components/Mf3rMferMint";

import ImageCarousel from "./components/ImageCarousel";
import CallToAction from './components/CallToAction'
import Timeline from './components/Timeline';
import React, { useState, useEffect } from 'react';
import { ethers, BigNumber } from "ethers";

import { FaDiscord, FaTwitter, FaYoutube, FaInstagram } from 'react-icons/fa'

export const SocialMediaLinks = (props: ButtonGroupProps) => (
  <ButtonGroup variant="ghost" color="gray.600" {...props}>
    <IconButton as="a" target="_blank" href="https://twitter.com/CROAKZ_" aria-label="Twitter" icon={<FaTwitter fontSize="20px" />} />
    <IconButton as="a" target="_blank" href="https://discord.gg/BnDHcUREXc" aria-label="Discord" icon={<FaDiscord fontSize="20px" />} />
    <IconButton as="a" target="_blank" href="https://www.instagram.com/croakz_" aria-label="Instagram" icon={<FaInstagram fontSize="20px" />} />
    <IconButton as="a" target="_blank" href="https://www.youtube.com/channel/UCQdIGH1zD4ovD9_RKNllGeA" aria-label="Youtube" icon={<FaYoutube fontSize="20px" />} />
  </ButtonGroup>
)

export const Copyright = (props: TextProps) => (
  <Text color={useColorModeValue('white', 'white')} fontSize="lg" fontWeight="medium" {...props}>
    &copy; {new Date().getFullYear()} CROAKZ, Inc... j/k we CC0 🤣
  </Text>
)

export const Logo = (props: TextProps) => {
  return (
    <Text color={useColorModeValue('white', 'white')} fontSize="lg" fontWeight="medium" {...props}>
    !croak 🐸
    </Text>
  )
}

function App() {
  const styles = {
    landingCroakContainer: {
        background: `url(${"croakz/croakz-hero-web.jpg"})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    }
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  let [provider, setProvider] = React.useState<ethers.providers.Web3Provider>();
  let [account, setAccount] = React.useState("");
  let [signer, setSigner] = React.useState({});
  let [etherBalance, setEtherBalance] = React.useState(BigNumber.from("0"));
  let [chainId, setChainId] = React.useState(0);
  let [chainName, setChainName] = React.useState("");


  async function connectProvider() {
    if(provider) {

      try {
        await provider.send("eth_requestAccounts", []);
      } catch (error) {
        /* @ts-ignore */
        const message = error.message || "";
        if (!message.match(/already processing/i)) { throw error; }
    
        const href = window.location.href;
        if (href.match(/connectOnLoad/)) { window.location.reload(); return; }
    
        const delimiter = href.match(/\?/) ? "&" : "?";
        window.location.href += delimiter + "connectOnLoad=true";
      }

      const _signer = provider.getSigner();

      setSigner(_signer);
      const _account = await _signer.getAddress();
      setAccount(_account);
      console.log(_account);
  
      const network = await provider.getNetwork();
      setChainId(network.chainId);
      setChainName(network.name != 'homestead'? network.name : 'mainnet');
      console.log(network.chainId);
      console.log(network.name);
  
      const _etherBalance = await provider.getBalance(_account);
      setEtherBalance(_etherBalance);
      console.log(_etherBalance);
    } else {
      console.log(`provider is ${provider}`);
    }
  }

  useEffect(() => {
    /* @ts-ignore */
    if (window.ethereum) {
      connectProvider();
      /* @ts-ignore */
      window.ethereum.on('accountsChanged', function (accounts) {
        connectProvider();
      });
      
      /* @ts-ignore */
      window.ethereum.on('networkChanged', function (networkId) {
        connectProvider();
      });
    }
  }, [provider]);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box fontSize="md" fontWeight="medium" style={styles.landingCroakContainer}>
        <Wrap p={{ base: '25', md: '50' }}>
            <WrapItem>
              <SocialMediaLinks />
            </WrapItem>
            <Spacer />
            <WrapItem>
              <ConnectButton 
                handleOpenModal={onOpen}
                provider={provider}
                setProvider={setProvider}
                connectProvider={connectProvider}
                account={account}
                signer={signer}
                etherBalance={etherBalance}
                chainId={chainId}
                chainName={chainName}
              />
            </WrapItem>
            <AccountModal isOpen={isOpen} onClose={onClose} provider={provider} setProvider={setProvider} />
        </Wrap>
        <Image style={{ visibility: 'hidden'}} src='croakz/croakz-hero-web-space.jpg' />
      </Box>

      <CallToAction />
      <Mf3rCroakMint signer={signer} account={account} chainId={chainId} connectProvider={connectProvider} provider={provider} setProvider={setProvider} />
      <Mf3rMferMint signer={signer} account={account} chainId={chainId} connectProvider={connectProvider} provider={provider} setProvider={setProvider} />
      <Timeline />

      <Flex
        className="beforeborder"
        flexDirection="column"
        justifyContent="top"
        bg="#6a7593"
        p={50}
      >
        <ImageCarousel />
      </Flex>

      <Box bg="#6a7593" as="footer" role="contentinfo" mx="auto" py="12" px={{ base: '50', md: '50' }}>
          <Stack bg="#6a7593">
            <Stack direction="row" spacing="4" align="center" justify="space-between">
              <Logo />
              <SocialMediaLinks />
            </Stack>
            <Copyright alignSelf={{ base: 'center', sm: 'start' }} />
          </Stack>
      </Box>
    </ChakraProvider>
  );
}

export default App;
