// hooks/index.ts
import { ethers } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { useContractCall } from "@usedapp/core";
import { useContractFunction__fix } from '../hooks/useContractFunction__fix';
import simpleContractAbi from "../abi/SimpleContract.json";
import { croakzContractAddress, crypToadzContractAddress, mf3rsContractAddress } from "../contracts"

const croakzBackendUrl = process.env.REACT_APP_CROAKZ_BACKEND_URL || '';
console.log(`Using backend ${croakzBackendUrl}`);
console.log(`Using CROAKZ contract ${croakzContractAddress}`);
console.log(`Using CrypToadz contract ${crypToadzContractAddress}`);
console.log(`Using mf3rs contract ${mf3rsContractAddress}`);

const simpleContractInterface = new ethers.utils.Interface(simpleContractAbi);
const croakzContract = new Contract(croakzContractAddress, simpleContractInterface);

export function handleWhitelistCheck(account: string, setWhitelistData: any) {
  // Simple GET request using fetch
  fetch(`${croakzBackendUrl}/whitelist/${account}`)
      .then(response => response.json())
      .then(data => setWhitelistData(data));
}

export function handleHolderDataCheck(account: string, setHolderData: any) {
  // Simple GET request using fetch
  fetch(`${croakzBackendUrl}/mf3rs/snapshot/${account}`)
      .then(response => response.json())
      .then(data => setHolderData(data));
}

export function useTotalSupply() {
  const [totalSupply]: any = useContractCall({
    abi: simpleContractInterface,
    address: '0xBfAb3649A8Aa6fBA5d48cD1Bcd493F284C74cB6f',
    method: "totalSupply",
    args: [],
  }) ?? [];
  return totalSupply;
}

export function useCrypToadzBalanceOf(address: string | null | undefined): number {
  const [balance]: any = useContractCall({
    abi: simpleContractInterface,
    address: crypToadzContractAddress,
    method: "balanceOf",
    args: [address],
  }) ?? [];
  return balance ? +balance : 0;
}

export function usePublicMint(setEvents: any) {
    const { state, send } = useContractFunction__fix(croakzContract, "publicMint", setEvents, {});
    return { state, send };
}

export function useWLMint(setEvents: any) {
  const { state, send } = useContractFunction__fix(croakzContract, "wlMint", setEvents, {});
  return { state, send };
}

export function useToadMint(setEvents: any) {
  const { state, send } = useContractFunction__fix(croakzContract, "toadMint", setEvents, {});
  return { state, send };
}

export function useMint(setEvents: any) {
  const { state, send } = useContractFunction__fix(croakzContract, "mint", setEvents, {});
  return { state, send };
}