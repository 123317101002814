import React, { useState, useEffect } from 'react';
import { Flex, Text, Button, Center, chakra, Box, useColorModeValue, Link } from "@chakra-ui/react";
import { utils } from "ethers";
import { handleHolderDataCheck } from "../hooks";
import { useToast } from '@chakra-ui/react'
import { ethers, BigNumber } from "ethers";
import mfersContractAbi from '../abi/mf3rs.json'

import { mf3rsContractAddress } from "../contracts"

import "./before.css";

import {
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
} from "@chakra-ui/react"

interface SnapshotData {
    "0": {
        isHolder: boolean;
        "data": {
            account: string;
            signature: string;
            project: number;
            held: number;
        }
    }
}

type Props = {
    account: string;
    chainId: number;
    provider: any;
    setProvider: any;
    connectProvider: any;
    signer: any;
};
  
export default function WLMint({ signer, account, chainId, provider, setProvider, connectProvider }: Props) {
    const [holderData, setHolderData] = React.useState<SnapshotData | undefined>(undefined);
    const [totalSupply, setTotalSupply] = React.useState(0);

    const toast = useToast()
    const status: "success" | "error" | "warning" | "info" | undefined = "error";
    const toastId = 'not-mainnet-error';

    const [events, setEvents] = useState<any | undefined>(undefined);
    const [value, setValue] = React.useState(1);

    console.log(`${account} holderData ${JSON.stringify(holderData)}`);


    async function handleConnectWallet() {
        /* @ts-ignore */
        if(!window.ethereum) {
            provider = new ethers.providers.Web3Provider(
            /* @ts-ignore */
            window.ethereum,
            "any"
            );
            setProvider(provider);
        }
        if(provider) {
            connectProvider();
        }
    }

    async function getTotalSupply() {
        const mf3rsContractInterface = new ethers.utils.Interface(mfersContractAbi);

        const mf3rs = {
            address: mf3rsContractAddress,
            abi: mf3rsContractInterface
        };  
        if(provider) {
            try {
                //await provider.send("eth_requestAccounts", []);
                const mf3rsContract = new ethers.Contract(mf3rs.address, mf3rs.abi, provider);
                const result  = await mf3rsContract.totalSupply();
                setTotalSupply(+result);
                console.log(`totalSupply is ${result}`);
            } catch(error) {
                console.log(error);
            }
        }
    }

    async function getTotalMinted() {
        const mf3rsContractInterface = new ethers.utils.Interface(mfersContractAbi);

        const mf3rs = {
            address: mf3rsContractAddress,
            abi: mf3rsContractInterface
        };  
        if(account && provider && signer) {
            //await provider.send("eth_requestAccounts", []);
            const mf3rsContract = new ethers.Contract(mf3rs.address, mf3rs.abi, provider);
            const result  = await mf3rsContract.balanceOf(account);
            console.log(`account has minted ${result}`);
        }
    }

    useEffect(() => {
        if(account && !holderData) {
            handleHolderDataCheck(account, setHolderData);
        }
        const timer = setTimeout(() => {
            if(chainId && +chainId !== 1 && !toast.isActive(toastId)) {
                toast({
                    id: toastId,
                    title: `${status}: Must be connected to mainnet`,
                    status: status,
                    duration: null,
                    isClosable: false,
                });
            } else if(chainId && +chainId === 1) {
                if(toast.isActive(toastId)) {
                    toast.close(toastId);
                }
            }
        }, 1000);
        if(account && provider && signer) {
            getTotalSupply();
            //getTotalMinted();
        }
        return () => clearTimeout(timer);
    }, [account, chainId, toast, provider, signer]);

    function sigToECDSAVars(signature: string | undefined) {
        if(signature) {
            //Extracting ECDSA variables
            // var r = signature.substr(0, 66);
            // var s = "0x" + signature.substr(66, 64);
            // var v = "0x" + signature.substr(130, 2);

            var r = "0x" + signature.slice(2, 64+2)
            var s = "0x" + signature.slice(64+2, 128+2)
            var v = "0x" + signature.slice(128+2, 130+2)

            return {
                v,
                r,
                s,
            };
        }
        console.log(`Error: could not get ECDSAVars from ${signature}`);
        return undefined
    }

    async function handleHolderMint() {
        const mf3rsContractInterface = new ethers.utils.Interface(mfersContractAbi);
        try {
            if(holderData && holderData.hasOwnProperty("0")) {
                const isHolder = holderData[0].isHolder;
                if(isHolder) {
                    const ecdsaVars = sigToECDSAVars(holderData[0].data.signature);
                    console.log(`ecdsaVars are ${JSON.stringify(ecdsaVars)}`);
                    const held = holderData[0].data.held;
                    const project = holderData[0].data.project;
                    const mf3rs = {
                        address: mf3rsContractAddress,
                        abi: mf3rsContractInterface
                    };
                    if(provider && signer) {
                        //await provider.send("eth_requestAccounts", []);
                        const mf3rsContract = new ethers.Contract(mf3rs.address, mf3rs.abi, signer);
                        console.log(`mint ${value}, ${project}, ${held}, ${ecdsaVars}`);
                        const tx = await mf3rsContract.mint(value, project, held, ecdsaVars);
                        console.log(`Transaction hash: ${tx.hash}`);
                        setEvents(`Waiting for confirmation...`);
                        const receipt = await tx.wait();
                        if(chainId === 1) {
                            setEvents(`https://etherscan.io/tx/${tx.hash}`);
                        } else {
                            setEvents(`https://rinkeby.etherscan.io/tx/${tx.hash}`);
                        }
                        console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
                        console.log(`Gas used: ${receipt.gasUsed.toString()}`);
                    }
                } else {
                    console.log(`Error: account ${account} not a holder in holderData: ${holderData}`);
                    toast({
                        /* @ts-ignore */
                        title: 'Cannot find your account in the CROAKZ snapshot',
                        status: status,
                        duration: null,
                        isClosable: true,
                    });
                }
            } else {
                console.log(`Error: Problem with holderData: ${holderData}`);
            }
        } catch(error) {
            /* @ts-ignore */
            if(error.error) {
                toast({
                    /* @ts-ignore */
                    title: error.error.message,
                    status: status,
                    duration: null,
                    isClosable: true,
                });
            }
        }
    }

    const parse = (val: string) => +val;

    const mystyle = {
        backgroundImage: 'url("croakz/explorer.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '27% 50%',
        filter: 'brightness(90%)'
    };

    const darken = {
        background: 'rgba(0, 0, 0, .55)'
    }

    return (
        <Flex
            className="beforeborder"
            style={mystyle}
            p={50}
            w="full"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                style={darken}
                w={{ base: "full", md: "75%", lg: "50%" }}
                px={4}
                py={20}
                textAlign={{ base: "left", md: "center" }}
            >
                <chakra.span
                    fontSize={{ base: "3xl", sm: "4xl" }}
                    fontWeight="extrabold"
                    letterSpacing="tight"
                    lineHeight="shorter"
                    color={useColorModeValue("white", "white")}
                    mb={6}
                >
                <chakra.span display="block">You a CROAK?</chakra.span>
                <chakra.span
                    display="block"
                    color={useColorModeValue("white", "white")}
                >
                    Claim your { holderData ? holderData[0].data.held : '' } mf3rs!!!
                </chakra.span>
                </chakra.span>

                <Center m={2}>
                    <Text color="white" fontSize="xl">
                        {totalSupply ? totalSupply : "?"} / 10000
                    </Text>
                </Center>

                <Center m={2}>
                    <NumberInput color={useColorModeValue("white", "white")} onChange={(valueString) => setValue(parse(valueString))} maxW={100} defaultValue={1} min={1} max={1000}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </Center>

                <Center display="inline-flex">
                    {account ?
                        (
                            <Box display="inline-flex" rounded="md" shadow="md">
                                <chakra.a
                                mt={2}
                                display="inline-flex"
                                alignItems="center"
                                justifyContent="center"
                                px={5}
                                py={3}
                                mb={4}
                                border="solid transparent"
                                fontSize="lg" fontWeight="medium"
                                w="full"
                                rounded="md"
                                target="_blank"
                                color={"white"}
                                bg={"#58ac9a"}
                                role="button"
                                _hover={{
                                    bg: "brand.50",
                                }}
                                onClick={handleHolderMint}
                                >
                                CROAKZ Holder Mint
                                </chakra.a>
                            </Box>
                        ) :
                        (
                            <Box display="inline-flex" rounded="md" shadow="md">
                                <chakra.a
                                mt={2}
                                display="inline-flex"
                                alignItems="center"
                                justifyContent="center"
                                px={5}
                                py={3}
                                mb={4}
                                border="solid transparent"
                                fontSize="lg" fontWeight="medium"
                                w="full"
                                rounded="md"
                                target="_blank"
                                color={"white"}
                                bg={"#58ac9a"}
                                role="button"
                                _hover={{
                                    bg: "brand.50",
                                }}
                                onClick={handleConnectWallet}
                                >
                                Connect Wallet To Mint
                                </chakra.a>
                            </Box>
                        )
                    }
                </Center>

                <Center m={2}>
                    <Text alignItems="center" justifyContent="center" color="white" fontSize="xl">
                        {events ? <Link target="_blank" href={events}>🔗 See Tx on etherscan 🔥</Link> : 'No Events'}
                    </Text>
                </Center>
            </Box>
        </Flex>
    );
}