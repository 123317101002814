import { Button, Box, Text } from "@chakra-ui/react";
import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";
import useWindowDimensions from "../hooks/windowsDimension"
import { ethers, BigNumber } from "ethers";
import React, { useState, useEffect } from 'react';

type Props = {
  handleOpenModal: any;
  provider: any;
  setProvider: any;
  connectProvider: any;
  account: string;
  signer: any;
  etherBalance: BigNumber;
  chainId: number;
  chainName: string;
};

export default function ConnectButton(
  { 
    handleOpenModal,
    provider,
    setProvider,
    connectProvider,
    account,
    signer,
    etherBalance,
    chainId,
    chainName
  }: Props) {

  const { width } = useWindowDimensions();

  async function handleConnectWallet() {
    /* @ts-ignore */
    if(window.ethereum) {
      provider = new ethers.providers.Web3Provider(
        /* @ts-ignore */
        window.ethereum,
        "any"
      );
      setProvider(provider);
    }
  }


  function renderByWidth(width: number) {
    return width > 640 ? 
      <>
        <Box px="3">
          <Text color="white" fontSize="md">
            {chainName}
          </Text>
        </Box>
        <Box px="3">
          <Text color="white" fontSize="md">
            {etherBalance && parseFloat(formatEther(etherBalance)).toFixed(3)} ETH
          </Text>
        </Box>
      </>
     : <></>
  }

  return account ? (
    <Box
      display="flex"
      alignItems="center"
      background="gray.700"
      borderRadius="xl"
      py="0"
    >
      {renderByWidth(width)}
      <Button
        onClick={handleOpenModal}
        bg="gray.800"
        border="1px solid transparent"
        _hover={{
          border: "1px",
          borderStyle: "solid",
          borderColor: "blue.400",
          backgroundColor: "gray.700",
        }}
        borderRadius="xl"
        m="1px"
        px={3}
        height="38px"
      >
        <Text color="white" fontSize="md" fontWeight="medium" mr="2">
          {account &&
            `${account.slice(0, 6)}...${account.slice(
              account.length - 4,
              account.length
            )}`}
        </Text>
        <Identicon />
      </Button>
    </Box>
  ) : (
    <Button
      onClick={handleConnectWallet}
      bg="blue.800"
      color="blue.300"
      fontSize="md"
      fontWeight="medium"
      borderRadius="xl"
      border="1px solid transparent"
      _hover={{
        borderColor: "blue.700",
        color: "blue.400",
      }}
      _active={{
        backgroundColor: "blue.800",
        borderColor: "blue.700",
      }}
    >
      { width > 640 ? 'Connect to a wallet' : 'Metamask' }
    </Button>
  );
}
