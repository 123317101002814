import { Global } from "@emotion/react"

export default function Fonts() {
    return (
        <Global styles={`
            @font-face {
                font-family: 'Super scratchy';
                src: url('./fonts/Superscratchy.ttf') format('truetype');
            }
            `}
        />
    );
}