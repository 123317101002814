import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as dotenv from 'dotenv';

import { DAppProvider, Config } from "@usedapp/core";

dotenv.config();

const config: Config = {
  // readOnlyChainId: ChainId.Rinkeby,
  // readOnlyUrls: {
  //   [ChainId.Mainnet]: 'https://mainnet.infura.io/v3/62687d1a985d4508b2b7a24827551934',
  //   [ChainId.Rinkeby]: 'https://rinkeby.infura.io/v3/62687d1a985d4508b2b7a24827551934'
  // },
}

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
