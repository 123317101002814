import { Image, Box } from "@chakra-ui/react"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./style.css";
  
export default function ImageCarousel() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 3,
        slidesToSlide: 3
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
      }
    };
    //const boxSize = (width / 3) - 40 - 25 + 'px';
    return (
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={true}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="image-item"
          deviceType="desktop"
        >
          <Box>
            <Image objectFit="cover" src="croakz/croak1.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak2.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak18.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak3.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak6.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak5.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak7.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak8.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak9.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak10.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak11.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak12.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak14.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak13.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak15.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak16.jpg" alt="Croak Carousel" />
          </Box>
          <Box>
            <Image objectFit="cover" src="croakz/croak17.jpg" alt="Croak Carousel" />
          </Box>
        </Carousel>
    );
}