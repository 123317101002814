import React, { useState, useEffect } from 'react';
import { Flex, SimpleGrid, chakra, Box, useColorModeValue } from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'
import "./before.css";

export default function CallToAction() {

    const toast = useToast()
    const status: "success" | "error" | "warning" | "info" | undefined = "error";
    const toastId = 'not-mainnet-error';
    const noMetamaskId = 'no-metamask-error';
    
    const mystyle = {
      backgroundImage: 'url("croakz/hypnotic-web.jpg")',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '65% 30%',
      //filter: 'brightness(80%)'
    };

    const darken = {
      background: 'rgba(0, 0, 0, .65)'
    }

    useEffect(() => {
      /* @ts-ignore */
      if(!window.ethereum) {
        toast({
            id: noMetamaskId,
            title: `${status}: Must have the metamask extension`,
            status: status,
            duration: null,
            isClosable: false,
        });
      }
    });

    return (
      <SimpleGrid className="beforeborder" style={mystyle} columns={{ base: 1, md: 2 }} spacing={0} p={50}>
        <Flex
          style={darken}
          direction="column"
          alignItems="start"
          justifyContent="center"
          px={{ base: 4, md: 8, lg: 20 }}
          py={24}
          zIndex={3}
        >
          <chakra.span
            color={useColorModeValue("white", "white")}
            fontSize="2xl" fontWeight="medium"
            letterSpacing="wider"
          >
            Welcome to the bog!
          </chakra.span>
          <chakra.span
            mb={4}
            fontSize={{ base: "4xl", md: "4xl", lg: "5xl" }}
            fontWeight="medium"
            color={useColorModeValue("white", "white")}
          >
            6,969 Unique CROAKZ
          </chakra.span>
          <chakra.p
            pr={{ base: 0, lg: 16 }}
            mb={4}
            fontSize="lg" fontWeight="medium"
            color={useColorModeValue("white", "white")}
            letterSpacing="wider"
          >
            CROAKZ are inspired by CrypToadz, and will be set wild to flourish in both the metaverse and meatspace.
          </chakra.p>
          <Box display="inline-flex" rounded="md" shadow="md">
            <chakra.a
              mt={2}
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              px={5}
              py={3}
              href="https://discord.gg/BnDHcUREXc"
              border="solid transparent"
              fontSize="lg" fontWeight="medium"
              w="full"
              rounded="md"
              target="_blank"
              color={useColorModeValue("white", "white")}
              bg={useColorModeValue("#7d626d", "#7d626d")}
              _hover={{
                bg: useColorModeValue("brand.700", "brand.600"),
              }}
            >
              Come croak in our Discord!
            </chakra.a>
          </Box>
        </Flex>
      </SimpleGrid>
    );
}